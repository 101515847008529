* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    visibility: visible;
    opacity: 1;
    min-height: 100%;
    height: 100%;
    font-family:-apple-system,
       BlinkMacSystemFont,
       'Segoe UI',
       Roboto,
       Oxygen,
       Ubuntu,
       Cantarell,
       'Open Sans',
       'Helvetica Neue',
       sans-serif;
    background-color: rgb(24,24,24);
}

body {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
}

header {
    nav {
        width: 100%;
        height: 60px;
        display: grid;
        grid-template-columns: 20% auto;
        grid-template-rows: 100%;

        .logo {
            grid-column: 1/2;
            height: 100%;
            width: 100%;
            max-width: 150px;
            align-self: center;
            z-index: 1000;
        }

        #logo {
            height: 100%;
            width: 100%;
            padding-top: 10px;
            padding-left: 10px;
            fill: transparent;

            path:nth-child(1) {
                stroke-dasharray: 367px;
                stroke-dashoffset: 367px;
                animation: logo 2s ease forwards 0.5s, fill-letter 1.5s ease forwards 5s;
            }

            path:nth-child(2) {
                stroke-dasharray: 417px;
                stroke-dashoffset: 417px;
                animation: logo 2s ease forwards 1.2s,
                    fill-letter 1.5s ease forwards 5s;
            }

            path:nth-child(3) {
                stroke-dasharray: 520px;
                stroke-dashoffset: 520px;
                animation: logo 2s ease forwards 1.4s,
                    fill-letter 1.5s ease forwards 5s;
            }

            path:nth-child(4) {
                stroke-dasharray: 365px;
                stroke-dashoffset: 365px;
                animation: logo 2s ease forwards 1.6s,
                    fill-letter 1.5s ease forwards 5s;
            }

            path:nth-child(5) {
                stroke-dasharray: 543px;
                stroke-dashoffset: 543px;
                animation: logo 2s ease forwards 1.8s,
                    fill-letter 1.5s ease forwards 5s;
            }

            path:nth-child(6) {
                stroke-dasharray: 408px;
                stroke-dashoffset: 408px;
                animation: logo 2s ease forwards 2s,
                    fill-letter 1.5s ease forwards 5s;
            }

            path:nth-child(7) {
                stroke-dasharray: 460px;
                stroke-dashoffset: 460px;
                animation: logo 2s ease forwards 2.2s,
                    fill-letter 1.5s ease forwards 5s;
            }

            path:nth-child(8) {
                stroke-dasharray: 74px;
                stroke-dashoffset: 74px;
                animation: logo 2s ease forwards 2.4s,
                    fill-letter 1.5s ease forwards 5s;
            }

            path:nth-child(9) {
                stroke-dasharray: 437px;
                stroke-dashoffset: 437px;
                animation: logo 2s ease forwards 2.6s,
                    fill-letter 1.5s ease forwards 5s;
            }

            path:nth-child(10) {
                stroke-dasharray: 365px;
                stroke-dashoffset: 365px;
                animation: logo 2s ease forwards 2.8s,
                    fill-letter 1.5s ease forwards 5s;
            }

            path:nth-child(11) {
                stroke-dasharray: 296px;
                stroke-dashoffset: 296px;
                animation: logo 2s ease forwards 3s,
                    fill-letter 1.5s ease forwards 5s;
            }

            line:nth-child(12) {
                stroke-dasharray: 64px;
                stroke-dashoffset: 64px;
                animation: logo 5s ease forwards 0s;
            }

            line:nth-child(13) {
                stroke-dasharray: 372px;
                stroke-dashoffset: 372px;
                animation: logo 5s ease forwards 0s;
            }

            line:nth-child(14) {
                stroke-dasharray: 372px;
                stroke-dashoffset: 372px;
                animation: logo 5s ease forwards 0s;
            }
        }
    }
}

.lazy {
    visibility: hidden;
    height: 0px;
    width: 0px;
    position: absolute;

    &.background {
        background-image: url('../img/Background_mini.png');
    }

    &.mountain {
        background-image: url('../img/Mountain_mini.png');
    }

    &.machu {
        background-image: url('../img/Machu_Pichu_mini.png');
    }

    &.luke {
        background-image: url('../img/Luke_mini.png');
    }

    &.desk {
        background-image: url('../img/desk-mini.jpg');
    }

    &.headshot {
        background-image: url('../img/headshot_mini.jpg');
    }
}

.ping {
    position: absolute;
    z-index: 100;
    right: 20px;
    bottom: 0px;
    height: 120px;
    width: 160px;
    transform: translateY(66.6%);
    background-color: rgba(224, 34, 56, 1);
    transition: transform 1s;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr 1fr;
    border-radius: 5px 5px 0px 0px;

     @media only screen and (min-width: 600px) {
         visibility: visible;
     }

     @media only screen and (max-width: 600px) {
        visibility: hidden;
     }

    &:hover {
        transform: translateY(0%);
    }

    .title {
        width: 100%;
        height: 100%;
        grid-row: 1/2;
        display: flex;
        align-items: center;
        justify-content: center;
        color: aliceblue;   
        font-size: 20px;
        border-bottom: 2px white solid;
    }

    .message {
        grid-row: 2/3;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        color: aliceblue;
        font-size: 10px;
    }

    .button {
        cursor: pointer;
        grid-row: 3/4;
        height: 70%;
        width: 60%;
        border-radius: 5px;
        border: 3px solid;
        display: flex;
        align-items: center;
        justify-self: center;
        justify-content: center;
        color: aliceblue;
    }
}

.home {
    position: absolute;
    display: grid ;
    grid-template-columns: 10% 1fr 1fr 10%;

    @media only screen and (min-height: 750px) and (min-width: 600px) {
        grid-template-rows: 1fr 1fr 1fr;
    }

    @media only screen and (max-height: 750px) and (min-width: 600px) {
        grid-template-rows: 1fr 1fr 2fr;
    }

    @media only screen and (max-width: 600px) {
        grid-template-rows: 1fr 2fr 2fr;
    }

    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  
    .desk {
        position: absolute;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        filter: grayscale(20%);
        z-index: -2;
    }

    .cover {
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: -1;
        background: radial-gradient(circle, rgba(0, 0, 0, 0.5) var(--pos, 100%), rgba(0, 0, 0, 1) 0%);
        animation: background-init 1.5s ease forwards;
    }

    .headshot {
        position: absolute;
        

        @media only screen and (min-width: 600px) and (min-height: 500px) {
            top: 20px;
            right: 20px;
            height: 100px;
            width: 100px;
        }

        @media only screen and (max-width: 600px) {
            top: 10px;
            right: 10px;
            height: 60px;
            width: 60px;
        }
        @media only screen and (max-height: 500px) {
            top: 10px;
            right: 10px;
            height: 60px;
            width: 60px;
        }
        
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        animation: zoom 1s ease forwards;

        .ring {
            position: absolute;
            border-radius: 20%;
            height: 100%;
            width: 100%;
            border-width: 2px;
            align-content: center;
            justify-content: center;
            box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.4);
            border-style: solid;
            border-color: rgba(224, 34, 56, 0.6);
        }

        #headshot-img {
            position: absolute;
            border-radius: 12%;
            height: 85%;
            width: 85%;
            object-fit: cover;
            box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.4);
            filter: saturate(1.2);
        }
    }

    .center-panel {
        grid-column: 2/4;
        grid-row: 2/3;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: 1fr 1fr;

        .summary {
            grid-column: 1/3;
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: 10% 1fr 5% 1fr 5% 1fr 10%;
            grid-template-rows: 25% 3fr 1fr 25%;
            color: rgba(240, 248, 255, 0.95);
            animation: slide-fade 2s ease forwards;

            .first-row {
                grid-row: 2/3;
                grid-column: 2/7;
                text-align: center;
                align-self: center;

                @media only screen and (min-width: 600px) and (min-height: 500px) {
                    font-size: 85px;
                }

                @media only screen and (max-width: 600px) {
                    font-size: 12vw;
                }

                @media only screen and (max-height: 500px) {
                    font-size: 12vh;
                }
                font-weight: bold;
            }

            .second-row {
                align-self: center;
                text-align: center;
                // font-weight: bold;
                grid-row: 3/4;
                @media only screen and (min-width: 600px) and (min-height: 500px) {
                     @media only screen and (max-height: 650px) {
                        font-size: 25px;
                     }

                     @media only screen and (min-height: 650px) {
                        font-size: 30px;
                     }
                }

                @media only screen and (max-width: 600px) {
                    display: none;
                }

                @media only screen and (max-height: 500px) {
                    display: none;
                }
            }

            .divider-1,
            .divider-2 {
                font-size: 40px;
            }

            .divider-1 {
                grid-column: 3/4;
            }

            .divider-2 {
                grid-column: 5/6;
            }

            .summary-1 {
                grid-column: 2/3;
            }

            .summary-2 {
                grid-column: 4/5;
            }

            .summary-3 {
                grid-column: 6/7;
            }
        }
    }
        
    .links {
        grid-row: 3/4;
        grid-column: 1/5;
        width: 100%;
        height: 100%;
        display: grid;
        @media only screen and (min-width: 600px) {

            @media only screen and (max-height: 650px) {
                grid-template-rows: 15% 60px auto 80px;
                font-size: 18px;
            }

            @media only screen and (min-height: 650px) {
                grid-template-rows: 5% 60px auto 80px;
                font-size: 20px;
            }
        }

        @media only screen and (max-width: 600px) {
            font-size: 4.5vw;
            grid-template-rows: 5% 120px auto 80px;
        }

        .local {
            grid-row: 2/3;
            width: 100%;
            height: 100%;
            display: grid;
            @media only screen and (min-width: 600px) {
                 grid-template-columns: 20% 1fr 5% 1fr 20%;
                 grid-template-rows: 100%;
            }

            @media only screen and (max-width: 600px) {
                 grid-template-columns: 15px auto 15px;
                 grid-template-rows: 1fr 15px 1fr;
            }

            text-align: center;
            color: rgba(240, 248, 255, 0.8);
            animation: fade 3s ease forwards;

            .about {
                @media only screen and (min-width: 600px) {
                    grid-column: 2/3;
                    grid-row: 1/2;
                    width: 100%;
                    max-width: 300px;
                }

                @media only screen and (max-width: 600px) {
                    grid-column: 2/3;
                    grid-row: 1/2;
                    width: 100%;
                }
            
                @media only screen and (max-height: 650px) {
                    height: 80%;
                }

                @media only screen and (min-height: 650px) {
                    height:100%;
                }

                cursor: pointer;
                text-decoration: none;
                color: rgba(240, 248, 255, 0.8);
                border-radius: 5px;
                border-width: 0px;
                border-style: solid;
                margin-left: auto;
                display: flex;
                background-color: rgba(224, 34, 56, 0.8);
                transition: background-color 0.5s;

                &:hover {
                    background-color: rgba(224, 34, 56, 1);
                }

                .button {
                    width: 100%;
                    align-self: center;
                }
            }

            .projects {
                @media only screen and (min-width: 600px) {
                    grid-column: 4/5;
                    grid-row: 1/2;
                    width: 100%;
                    max-width: 300px;
                }

                @media only screen and (max-width: 600px) {
                    grid-column: 2/3;
                    grid-row: 3/4;
                    width: 100%;
                }

                @media only screen and (max-height: 650px) {
                    height: 80%;
                }

                @media only screen and (min-height: 650px) {
                    height: 100%;
                }
                
                cursor: pointer;
                text-decoration: none;
                color: rgba(240, 248, 255, 0.8);
                border-radius: 5px;
                border-width: 2px;
                border-style: solid;
                margin-right: auto;
                display: flex;
                background-color: rgba(0,0,0, 0.6);
                transition: all 0.5s;

                &:hover {
                    background-color: rgba(224, 34, 56, 1);
                }

                .button {
                    width: 100%;
                    align-self: center;
                }
            }
        }

        .center {
            display: table;
            width: 100%;
            height: 100vh;
        }

        .social {
            grid-row: 4/5;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            color: rgba(224, 34, 56, 1);
            animation: fade 3s ease forwards;

            #social-group {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                font-size: 30px;

                .social-list {
                    padding-left: 0px;
                }

                li {
                    display: inline-block;
                }

                li a {
                    color: rgba(256, 256, 256, 0.6);
                    border-radius: 6px;
                    list-style-type: none;
                    display: inline-block;
                    @media only screen and (min-width: 600px) and (min-height: 500px) {
                         width: 60px;
                         height: 60px;
                         line-height: 60px;
                    }

                    @media only screen and (max-width: 600px) {
                         width: 40px;
                         height: 40px;
                         line-height: 40px;
                    }
                    @media only screen and (max-height: 500px) {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                    }
                   
                    padding: 1%;
                    border: 1px solid rgba(256, 256, 256, 0.6);
                    cursor: pointer;
                    margin-left: 10px;
                    margin-bottom: 20px;
                    transition: ease .3s;

                    &:hover {
                        color: rgba(256, 256, 256, 1);
                        border: 1px solid rgba(256, 256, 256, 1);
                    }
                }
            }

            .social-list:hover>li {
                opacity: 0.5;
            }

            .social-list:hover>li:hover {
                color: rgba(224, 34, 56, 1);
                opacity: 1;
            }
        }
    }
}

@keyframes logo {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fill-letter {
    60% {
        fill: white;
    }

    100% {
        fill: white;
    }
}

@keyframes background-init {
    to {
        --pos: 100%;
    }
}

@keyframes slide-fade {
    from {
        transform: translateX(-30px);
        opacity: 0;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }
}

@keyframes zoom {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
